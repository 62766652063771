import React, { useState, useEffect, ReactNode } from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { ContainerCommon, Title, SearchIcon, SelectDivider, CommonSelect } from '../../styles/Common';
import {
    Filter,
    FilterResults,
    FilterResultsNumber,
    FilterResultsText,
    InputSearchWithIconWrapper,
    InputSearchWithIcon,
    InputSearch,
    ProductSearch,
    ProductSearchTitle,
    ProductSearchBox,
    ProductSearchCategory,
    ProductSearchText,
    SearchedSites,
    SiteContainer,
    SiteTitle,
    SiteSubtitle,
    SiteText,
    SiteTextWrapper,
    SiteTextInjected,
    SendButtonWrapper,
    HighlightedText,
    HighlightedHeader,
} from './SearchEngineStyle';
import { categoryNamesWithIdObject } from '../../templateObjects/categoryNamesWithIdObject';
import { navigate } from 'gatsby';
import { useSearchSites } from '../../hooks/useSearchSites';
import { urlHasSearchParams } from '../../utils/urlHasSearchParams';
import { SEARCH_API_VIEWS_FULLTEXT } from '../../constants/commonConstants';
import { SendButtonGreyGradient } from '../../styles/Common';
import { Spinner } from '../Loader/LoaderStyle';

const SearchEngine = () => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const url_string = typeof window !== 'undefined' ? window.location.href : process.env.SITE_URL;
    const urlParamsList = url_string.split('?');
    let hasUrlParams = urlHasSearchParams(url_string, urlParamsList);
    const queryString = typeof window !== 'undefined' ? window.location.search : process.env.SITE_URL;
    const urlParams = new URLSearchParams(queryString);
    const [numberOfResults, setNumberOfResults] = useState(0);
    const [productSearchCategory, setProductSearchCategory] = useState('0');
    const [productSearchTitle, setProductSearchTitle] = useState('');
    const [searchSitesValue, setSearchSitesValue] = useState('');
    const [searchSitesResults, setSearchSitesResults] = useState([]);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const resultsLimit = 10;
    const trimHtmlTagsRegexp = /<\/?[^>]+(>|$)/g;
    const offsetZero = 0;

    const displayProductCategories = (): ReactNode =>
        categoryNamesWithIdObject.map((category) => (
            <option key={category.id} value={category.id}>
                {t(category.name)}
            </option>
        ));

    const prepareLinkToSearchProducts = (): string => {
        if (productSearchCategory !== '0' && productSearchTitle.length > 0) {
            return `/${language}/products?field_product_main_search_tid=${productSearchCategory}&title=${productSearchTitle}`;
        } else {
            return `/${language}/products?${
                productSearchCategory !== '0' ? `field_product_main_search_tid=${productSearchCategory}` : ''
            }${productSearchTitle.length > 0 ? `title=${productSearchTitle}` : ''}`;
        }
    };
    const searchProducts = (): void => {
        if (productSearchCategory !== '0' || productSearchTitle.length > 0) {
            const searchProductsUrl = prepareLinkToSearchProducts();
            navigate(searchProductsUrl);
        }
    };

    const prepareDate = (date: string): string => {
        let currentDate = new Date(+date * 1000);
        let convertMonth = +currentDate.getMonth() + 1;
        let month = currentDate.getMonth() + 1 < 10 ? '0' + convertMonth : convertMonth;
        let day = currentDate.getDay() < 10 ? '0' + currentDate.getDay() : currentDate.getDay();
        return `${day}.${month}.${currentDate.getFullYear()}`;
    };

    const highlightText = (text: string): ReactNode => {
        if (searchSitesValue && text.indexOf(searchSitesValue) >= 0) {
            return (
                <>
                    {text.slice(0, text.indexOf(searchSitesValue)).replace(trimHtmlTagsRegexp, '')}
                    <HighlightedText dangerouslySetInnerHTML={{ __html: `<em>${searchSitesValue}</em>` }} />
                    {highlightText(
                        text
                            .slice(text.indexOf(searchSitesValue) + searchSitesValue.length)
                            .replace(trimHtmlTagsRegexp, '')
                    )}
                </>
            );
        } else {
            return text.slice(0, text.indexOf(searchSitesValue)).replace(trimHtmlTagsRegexp, '');
        }
    };

    const highlightHeader = (text: string): ReactNode => {
        if (searchSitesValue && text.indexOf(searchSitesValue) >= 0) {
            return (
                <>
                    {text.slice(0, text.indexOf(searchSitesValue))}
                    <HighlightedHeader dangerouslySetInnerHTML={{ __html: `<em>${searchSitesValue}</em>` }} />
                    <HighlightedHeader>
                        {highlightHeader(text.slice(text.indexOf(searchSitesValue) + searchSitesValue.length))}
                    </HighlightedHeader>
                </>
            );
        } else {
            return text;
        }
    };

    const getHighlightFieldForCurrentLanguage = (highlights: any): any => {
        for (const highlight in highlights) {
            if (`${language}.body` === highlight) {
                return highlights[highlight];
            } else if (highlight.includes(`${language}.`)) {
                return highlights[highlight];
            }
        }
    };

    const getHighlightedFieldData = (highlight: Array<string>): ReactNode => {
        const highlightedField = getHighlightFieldForCurrentLanguage(highlight);
        return (
            <SiteTextInjected>
                {highlightedField.map((text, index) => (
                    <span
                        key={index}
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                ))}
            </SiteTextInjected>
        );
    };

    const displayBody = (fields: any, highlight: any): ReactNode => {
        const { type } = fields;
        switch (type) {
            case 'product':
                return <SiteText>{highlightText(fields.field_product_long_text)}</SiteText>;
            case 'event':
                return fields?.summary ? (
                    <SiteTextInjected>
                        <span dangerouslySetInnerHTML={{ __html: fields?.summary }} />{' '}
                    </SiteTextInjected>
                ) : (
                    getHighlightedFieldData(highlight)
                );
            case 'branch':
                return (
                    <SiteTextInjected
                        dangerouslySetInnerHTML={{
                            __html: fields.field_description,
                        }}
                    />
                );
            default:
                return getHighlightedFieldData(highlight);
        }
    };

    const displaySites = (): ReactNode =>
        searchSitesResults
            .filter((site) => site && site._source && site._source[language])
            .map((site, index: number) => displaySite(site, index));

    const displaySite = (site: any, index: number): ReactNode => (
        <SiteContainer key={index}>
            <SiteTextWrapper>
                {site._source[language].title && (
                    <SiteTitle to={site._source[language].url ? `/${language}${site._source[language].url}` : '#'}>
                        {highlightHeader(site._source[language].title)}
                    </SiteTitle>
                )}
                {displaySubTitle(
                    site._source[language].type,
                    site._source[language].category ?? site._source[language].title
                )}
                {displayBody(site._source[language], site.highlight)}
            </SiteTextWrapper>
        </SiteContainer>
    );

    const searchSites = async (searchByParam = false, initOffset = false): Promise<void> => {
        if (!isFirstRender && searchByParam && urlParams.get(SEARCH_API_VIEWS_FULLTEXT)?.length > 0) {
            setIsLoading(true);
            const elasticData = await useSearchSites(
                language,
                urlParams.get(SEARCH_API_VIEWS_FULLTEXT),
                offsetZero,
                resultsLimit
            );
            setSearchSitesResults(elasticData?.hits?.hits);
            setNumberOfResults(elasticData?.hits?.total?.value);
            setSearchSitesValue(urlParams.get(SEARCH_API_VIEWS_FULLTEXT));
            setIsLoading(false);
            setOffset(resultsLimit);
        } else if (searchSitesValue.length > 0) {
            setIsLoading(true);
            const elasticData = await useSearchSites(language, searchSitesValue, offsetZero, resultsLimit);
            setSearchSitesResults(elasticData?.hits?.hits);
            setNumberOfResults(elasticData?.hits?.total?.value);
            setIsLoading(false);
            setOffset(resultsLimit);
        } else if (isFirstRender && searchByParam && urlParams.get(SEARCH_API_VIEWS_FULLTEXT)?.length > 0) {
            setIsLoading(true);
            const elasticData = await useSearchSites(
                language,
                urlParams.get(SEARCH_API_VIEWS_FULLTEXT),
                offsetZero,
                resultsLimit
            );
            setSearchSitesResults(elasticData?.hits?.hits);
            setNumberOfResults(elasticData?.hits?.total?.value);
            setSearchSitesValue(urlParams.get(SEARCH_API_VIEWS_FULLTEXT));
            setIsLoading(false);
            setOffset(resultsLimit);
        }
    };

    const searchSitesByEnter = (event: any): void => {
        if (event.key === 'Enter' && searchSitesValue.length > 0) {
            event.preventDefault();
            searchSites(false, true);
        }
    };

    useEffect(() => {
        if (hasUrlParams) {
            searchSites(true);
        }
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        setSearchSitesValue('');
        searchSites(true);
    }, [urlParams.get(SEARCH_API_VIEWS_FULLTEXT)]);

    const loadMoreResults = async (): Promise<void> => {
        setIsLoading(true);
        const elasticData = await useSearchSites(language, searchSitesValue, offset, resultsLimit);
        setSearchSitesResults((searchSitesResults) => [...searchSitesResults, elasticData?.hits?.hits].flat());
        setOffset((offset) => offset + resultsLimit);
        setIsLoading(false);
    };

    const cleanSearch = (): void => {
        searchSites(false, true);
    };

    const displaySubTitle = (type: string, title: string): ReactNode => {
        switch (type) {
            case 'product':
                return <SiteSubtitle>{t('searchProducts')}</SiteSubtitle>;
            default:
                return <SiteSubtitle>{title}</SiteSubtitle>;
        }
    };

    return (
        <ContainerCommon>
            <Title>{t('search')}</Title>
            <Filter>
                <InputSearchWithIconWrapper>
                    <InputSearchWithIcon>
                        <InputSearch
                            value={searchSitesValue}
                            onChange={(e) => setSearchSitesValue(e.target.value)}
                            onKeyDown={searchSitesByEnter}
                        />
                        <SelectDivider />
                        <SearchIcon onClick={cleanSearch} />
                    </InputSearchWithIcon>
                </InputSearchWithIconWrapper>
                <FilterResults>
                    <FilterResultsNumber>{numberOfResults}</FilterResultsNumber>
                    <FilterResultsText>{t('results')}</FilterResultsText>
                </FilterResults>
            </Filter>
            {searchSitesResults?.length > 0 && <SearchedSites>{displaySites()}</SearchedSites>}
            {isLoading && (
                <Spinner className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </Spinner>
            )}
            {searchSitesResults?.length < numberOfResults && (
                <SendButtonWrapper>
                    <SendButtonGreyGradient onClick={loadMoreResults}>{t('loadMore')}</SendButtonGreyGradient>
                </SendButtonWrapper>
            )}
            <ProductSearch>
                <ProductSearchTitle>{t('doYouWantToSearch')}</ProductSearchTitle>
                <ProductSearchBox>
                    <ProductSearchCategory>
                        <CommonSelect onChange={(e) => setProductSearchCategory(e.target.value)} width={'100%'}>
                            <option value={'0'}>{t('pleaseMakeSelection')}</option>
                            {displayProductCategories()}
                        </CommonSelect>
                    </ProductSearchCategory>
                    <div />
                    <ProductSearchText>
                        <InputSearchWithIcon>
                            <InputSearch
                                value={productSearchTitle}
                                onChange={(e) => setProductSearchTitle(e.target.value)}
                            />
                            <SelectDivider />
                            <SearchIcon onClick={searchProducts} />
                        </InputSearchWithIcon>
                    </ProductSearchText>
                </ProductSearchBox>
            </ProductSearch>
        </ContainerCommon>
    );
};

export default SearchEngine;
