import axios from 'axios';

export const useSearchSites = async (language, value, offset, limit) => {
    const data = await axios.post(
        `${process.env.DRUPAL_URL}/graphql`,
        {
            query: `query searchPages($language: String!, $value: String!, $limit: Int!, $offset: Int!) {
                        elasticsearch(language: $language, value: $value,  limit: $limit, offset: $offset) {
                            data
                        }
                    }
            `,
            variables: {
                language: language,
                value: value,
                limit: limit,
                offset: offset,
            },
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return JSON.parse(data?.data?.data?.elasticsearch?.data) ?? [];
};
