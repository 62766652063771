import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import Header from '../components/HeaderDynamicPages/Header';
import SearchEngine from '../components/SearchEngine/SearchEngine';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import Alternate from '../components/Seo/Alternate';
import searchSiteTranslations from '../JSON/StaticSitesTranslations/searchSiteTranslations.json';
import { DE, GLOBAL } from '../constants/languageCode';

const SearchSite = () => {
    const { language, changeLanguage } = useI18next();
    const { t } = useTranslation();
    const languagesWithUrls = [];
    process.env.LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push({ language: language, url: 'search-site' })
    );

    useEffect(() => {
        if (language === GLOBAL) {
            changeLanguage(DE);
        }
    }, []);

    return (
        <Layout title={t('search')} languageVersionsUrl={searchSiteTranslations}>
            <Alternate languages={languagesWithUrls} />
            <Header item={t('searchHeader')} />
            <SearchEngine />
        </Layout>
    );
};

export default SearchSite;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
