export const categoryNamesWithIdObject = [
    {
        name: 'compressors',
        id: '8',
    },
    {
        name: 'compressed.air.treatment',
        id: '243',
    },
    {
        name: 'special.gases',
        id: '253',
    },
    {
        name: 'controls',
        id: '539',
    },
];
