import { SEARCH_API_VIEWS_FULLTEXT } from '../constants/commonConstants';

export const urlHasSearchParams = (url_string: string, urlParams): boolean => {
    if (url_string.length > 1 && urlParams[1] && urlParams[1] !== '') {
        if (
            urlParams[1]
                .split('&')
                .map((param) => param.slice(0, param.indexOf('=')))
                .some((param) => SEARCH_API_VIEWS_FULLTEXT === param)
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
